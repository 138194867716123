let twitterlink = "https://t.me/ETH6900Portal";

let telegramlink = "https://t.me/ETH6900Portal";

let pinksalelink = "https://t.me/ETH6900Portal";

let pancakeswaplink = "https://t.me/ETH6900Portal";

let dexviewlink = "https://t.me/ETH6900Portal";

let bscscanlink = "https://t.me/ETH6900Portal";

let coinmarketcaplink = "";

let coinsultlink = "https://t.me/ETH6900Portal";

let tokenAddress = "xxx";

let whitepaperlink = "";

let auditlink = "https://t.me/ETH6900Portal";

let YooshiApelink = "https://t.me/ETH6900Portal";

export { twitterlink, telegramlink, dexviewlink, bscscanlink, pancakeswaplink, coinmarketcaplink, pinksalelink, coinsultlink, tokenAddress, whitepaperlink, YooshiApelink, auditlink };
