import React, { useEffect, useState } from "react";
// import chart from "../assests/images/icon-chart.svg";
import bnbIcon from "../assests/images/icon-bnb.svg";
import pinksaleIcon from "../assests/images/icon-pinksale.svg";
import coinmarketcapIcon from "../assests/images/icon-coinmarketcap.svg";
import dexview_logo from "../assests/images/dexview_logo.png";
import pancakeswapIcon from "../assests/images/icon-pancakeswap.svg";
import coinsultIcon from "../assests/images/icon-coinsult.svg";
import aiImg1 from "../assests/images/heading1.png";
import aiImg2 from "../assests/images/heading2.jpg";
import tokenomicss from "../assests/images/IMG_8734.jpg";
import aiImg4 from "../assests/images/icon-ai-img4.svg";
import roadmap from "../assests/images/roadmap.svg";
import roadmapmob from "../assests/images/Group 8.svg";
// import sparkleIcon1 from "../assests/images/icon-sparkle1.svg";
import sparkleIcon2 from "../assests/images/icon-sparkle2.svg";
import sparkleIcon3 from "../assests/images/icon-sparkle3.svg";
import sparkleIcon4 from "../assests/images/icon-sparkle4.svg";
import sparkleIcon5 from "../assests/images/icon-sparkle5.svg";
import AOS from "aos";
import "aos/dist/aos.css";

import "./style.css";

import AliceCarousel from "react-alice-carousel";
import "react-alice-carousel/lib/alice-carousel.css";
import CircularProgressBar from "../CircularProgressBar";
import { coinsultlink, dexviewlink, pancakeswaplink, pinksalelink, tokenAddress } from "../sociallinks";

export default function Home() {
  let [isMobile, setisMobile] = useState(false);
  const [iscopied, setIsCopied] = useState(false);

  const handleCopyClick = async () => {
    try {
      await navigator.clipboard.writeText(tokenAddress);
      setIsCopied(true);
    } catch (err) {
      console.error("Failed to copy text: ", err);
    }
  };
  function redirectToNewTab(url) {
    window.open(url, "_blank");
  }

  useEffect(() => {
    setisMobile(window.innerWidth < 800);
    AOS.init({ duration: 2000 });
  }, []);
  const handleDragStart = (e) => e.preventDefault();

  const items = [
    <img src={bnbIcon} className="bnbIcon" alt="binance-icon" onDragStart={handleDragStart} />,
    <img src={pinksaleIcon} onClick={() => redirectToNewTab(pinksalelink)} alt="pinksale-icon" onDragStart={handleDragStart} />,
    <img src={coinmarketcapIcon} className="coinmarketcapIcon" alt="coinmarketcap-icon" onDragStart={handleDragStart} />,
    <img src={pancakeswapIcon} onClick={() => redirectToNewTab(pancakeswaplink)} className="pancakeswapIcon" alt="pancakeswap-icon" onDragStart={handleDragStart} />,
    <img src={dexview_logo} onClick={() => redirectToNewTab(dexviewlink)} className="dexviewIcon" alt="dexview-icon" onDragStart={handleDragStart} />,
    <img src={coinsultIcon} onClick={() => redirectToNewTab(coinsultlink)} className="coinsultIcon" alt="coinsult-icon" onDragStart={handleDragStart} />,
  ];

  const responsive = {
    0: { items: 1 },
    600: { items: 2 },
    1200: { items: 3 },
    1500: { items: 5 },
  };

  return (
    <>
      <div className="backdropDiv1">
        <div className="eclipseDiv"></div>
        <div id="home" className="mainWrapper1 faders" data-aos="fade-up">
          <div className="titleContent">
            <div>
              <div className="mainHeadingTxt">
                Welcome to
                <br />
                <div className="gradientTxt">ETH6900</div>
              </div>
            </div>
            <div className="titleInfo">The 'HarryPotterTrumpHomerSimpson6900Inu' token is not just a name; it's a symphony of iconic characters and 6900s that'll have you casting spells of laughter and wonder.</div>
            <div className="btn1" onClick={() => redirectToNewTab(dexviewlink)}>
              TELEGRAM
            </div>
            <div className="tokenomics-box address-box">
              <>
                <div className="copy">Token Address :{iscopied ? <i className="fas fa-check"></i> : <i onClick={handleCopyClick} className="far fa-copy"></i>}</div>
                <div className="address button-text">
                  <span>{tokenAddress}</span>
                </div>
              </>
            </div>
          </div>
          <img src={aiImg1} alt="ai-img" className="float-img" />
        </div>
      </div>

      <div className="backdropDiv2">
        <div className="mainWrapper2 faders">
          <div id="about" className="aboutWrapper faders" data-aos="fade-up">
            <img src={aiImg2} alt="ai-img" />
            <div className="aboutDiv">
              <div>
                <div className="aboutTitle">About</div>
                <div className="aboutMainTxtheading">ETH6900</div>
              </div>
              <div className="aboutMainTxt1"></div>
              <div className="aboutMainTxt2">Greetings, wizards, muggles, and fans of the inexplicably extraordinary! Prepare to be spellbound by the enchanting tale of the 'HarryPotterTrumpHomerSimpson6900Inu' token – 'ETH6900' a fusion of iconic personalities, supernatural flair, and a dash of meme magic that defies both logic and the confines of reality</div>
              <div className="aboutMainTxt2">picture a token so imaginative that it weaves the worlds of fantasy, politics, and yellow cartoon hilarity into an epic blockchain tapestry. </div>
            </div>
          </div>
        
          <div id="tokenomics" className="tokenomicsWrapper faders" data-aos="fade-up">
            <div className="tokenomics">
              <div className="tokenomicsFeatures">
                <div className="headingUnderlineWrapper">
                  <div className="tokenomicsTxt">Tokenomics</div>
                </div>
                <div className="tokenomics-div">
                  <div>
                    <div className="tokenomics-box">
                      <li>
                        Token Name : <span>HarryPotterTrumpHomerSimpson6900Inu</span>
                      </li>
                      <li>
                        Token Symbol: <span>ETH6900</span>
                      </li>
                      <li>
                        Total Supply: <span>6900</span>
                      </li>
                      <li>
                        Decimals: <span>18</span>
                      </li>
                    </div>
                    <br />
                    <div className="tokenomics-box">
                      <li>
                        Blockchain: <span>Ethereum Mainnet (ERC-20)</span>
                      </li>
                 
                      <li>
                       Liquidity: <span>1ETH</span>
                      </li>
                  
                      <li>
                        Token tax @buy: <span>0% Tax</span>
                      </li>
                      <li>
                        Token tax @sell: <span>0% Tax</span>
                      </li>
                    </div>
                  </div>
                  <div className="progress-bar-div">
                    <div className="progress-bar-align">
                      <div className="progress-bar-container">
                        <div className="tokenomicsImg">
                          <img src={tokenomicss} alt="sss"/>
                          </div>
                      </div>
                    </div>
                  </div>
                </div>
                <br/>
                <br/>

              </div>
            </div>
          </div>
          {/* <div id="roadmap" className="roadmapdiv faders" data-aos="fade-up">
            <div className="roadmapimg">{isMobile ? <img src={roadmapmob} alt="roadmap" /> : <img src={roadmap} alt="roadmap" />}</div>
          </div> */}
        </div>
      </div>
    </>
  );
}
